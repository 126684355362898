var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container-fluid" }, [
    _c("nav", { staticClass: "navigation-menu" }, [
      _c(
        "ul",
        { ref: "menu", staticClass: "navigation-menu-items" },
        _vm._l(_setup.menuItems, function (menuItem, index) {
          return _c(
            "li",
            {
              key: index,
              on: {
                mouseenter: function ($event) {
                  return _setup.onHover(index)
                },
                mouseleave: function ($event) {
                  _setup.showDropdown = null
                },
              },
            },
            [
              _c(
                menuItem.domElementTag,
                _vm._b(
                  {
                    tag: "component",
                    staticClass: "nav-item",
                    class: { "is-active": _setup.showDropdown === index },
                  },
                  "component",
                  menuItem.domElementTag === "router-link"
                    ? { to: menuItem.href }
                    : { href: menuItem.href, target: "_blank" },
                  false
                ),
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(menuItem.title) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              menuItem?.children && menuItem.isLoaded
                ? _c(
                    "div",
                    {
                      class: [
                        "nav-item-content",
                        { "is-visible": _setup.showDropdown === index },
                      ],
                    },
                    [
                      _c("div", { staticClass: "container-fluid" }, [
                        menuItem.type === "slider"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "nav-slider scroll scroll-x scroll-hide-bar",
                              },
                              _vm._l(
                                menuItem.children,
                                function (category, catIndex) {
                                  return _c(
                                    "router-link",
                                    {
                                      key: catIndex,
                                      staticClass: "item",
                                      attrs: { to: category.href },
                                    },
                                    [
                                      _c("base-img", {
                                        staticClass: "image",
                                        attrs: {
                                          src: category.imgUrl,
                                          alt: category.title,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(category.title)),
                                      ]),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        menuItem.type === "tree"
                          ? _c("div", { staticClass: "nav-tree" }, [
                              _c(
                                "ul",
                                { staticClass: "nav-tree-sidebar" },
                                _vm._l(
                                  menuItem.children,
                                  function (category, categoryIndex) {
                                    return _c(
                                      "li",
                                      {
                                        key: categoryIndex,
                                        on: {
                                          mouseenter: function ($event) {
                                            _setup.hoveredCategory = category
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          category.domElementTag,
                                          {
                                            tag: "component",
                                            staticClass: "sidebar-item",
                                            class: {
                                              active:
                                                _setup.hoveredCategory
                                                  ?.title === category.title,
                                            },
                                            attrs: { to: category.href },
                                          },
                                          [
                                            _c("base-img", {
                                              staticClass: "image",
                                              attrs: {
                                                src: _setup.getSrcCF(
                                                  category.imgUrl,
                                                  "50x50"
                                                ),
                                                alt: category.title,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(category.title)),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "nav-tree-items-wrapper" },
                                [
                                  _vm._l(
                                    menuItem.children,
                                    function (category, categoryIndex) {
                                      return _vm._l(
                                        category.children,
                                        function (child, childIndex) {
                                          return _c(
                                            child.domElementTag,
                                            {
                                              key: `${categoryIndex}_${childIndex}`,
                                              tag: "component",
                                              staticClass: "menu-item",
                                              class: {
                                                "is-visible":
                                                  _setup.hoveredCategory ===
                                                  category,
                                              },
                                              attrs: { to: child.href },
                                            },
                                            [
                                              child.imgUrl
                                                ? _c("base-img", {
                                                    staticClass: "image",
                                                    attrs: {
                                                      src: _setup.getSrcCF(
                                                        child.imgUrl,
                                                        "50x50"
                                                      ),
                                                      alt: child.title,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "title" },
                                                [_vm._v(_vm._s(child.title))]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }