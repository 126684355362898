<template>
    <div class="navigation-menu-mobile">
        <template v-if="categoryHistory.length === 0">
            <ul class="main-navigation">
                <li
                    v-for="(category, index) in items"
                    :key="index"
                >
                    <component
                        :is="category.domElementTag"
                        v-bind="category.domElementTag === 'router-link'
                            ? { to: category.href }
                            : { href: category.href, target: '_blank' }"
                        class="main-menu-item"
                        @click="handleCategoryClick(category)"
                    >
                        {{ category.title }}
                    </component>
                </li>
            </ul>
        </template>

        <template v-else>
            <button
                v-if="categoryHistory.length"
                class="back-button"
                @click="goBack"
            >
                <base-icon name="arrow-left" />
                {{ $t('site_ui_menu_back') }}
            </button>

            <div class="categories">
                <component
                    :is="hasChildren(category) ? 'div' : category.domElementTag"
                    v-for="(category, index) in currentCategories"
                    :key="index"
                    :to="!hasChildren(category) && category.href"
                    class="menu-item"
                    @click="handleCategoryClick(category)"
                >
                    <base-img
                        v-if="category.imgUrl"
                        :src="category.imgUrl"
                        :alt="category.title"
                        class="image"
                    />
                    <span class="title">{{ category.title }}</span>
                </component>
            </div>
        </template>
    </div>
</template>

<script setup>
import {
    defineEmits, defineProps, ref, watch,
} from 'vue';
import { useHead } from '@unhead/vue';
import { useRoute } from 'vue-router/composables';

const props = defineProps({
    drawer: {
        type: Boolean,
        default: false,
    },
    items: {
        type: Array,
        default: () => [],
    },
});

const emit = defineEmits(['update:drawer']);

const route = useRoute();
watch(route, () => {
    emit('update:drawer', false);
});

const categoryHistory = ref([]);

const currentCategories = ref(props.categories);

const hasChildren = (category) => category.children && category.children.length > 0;

const handleCategoryClick = (category) => {
    if (hasChildren(category)) {
        categoryHistory.value.push(currentCategories.value);
        currentCategories.value = category.children;
    }
};
const goBack = () => {
    if (categoryHistory.value.length) {
        currentCategories.value = categoryHistory.value.pop();
    }
};

useHead({
    bodyAttrs: {
        class: {
            'body-nav-drawer': () => props.drawer,
        },
    },
});
</script>

<script>
export default {
    model: {
        prop: 'drawer',
        event: 'update:drawer',
    },
};
</script>

<style lang="scss">
@import "../scss/05-components/the-navigation-menu-mobile";
</style>
