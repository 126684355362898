var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    [
      _c("nav", { staticClass: "navbar" }, [
        _c(
          "div",
          { staticClass: "container-fluid navbar-container" },
          [
            _c(
              "div",
              { staticClass: "navbar-main" },
              [
                _c("logo", { staticClass: "navbar-brand me-3" }),
                _vm._v(" "),
                !_vm.showAppBtnBack && _vm.showCategories()
                  ? _c(
                      "button",
                      {
                        staticClass: "navbar-toggler",
                        attrs: {
                          type: "button",
                          "aria-expanded": _vm.drawer ? "true" : "false",
                          "aria-label": "Toggle navigation",
                        },
                        on: {
                          click: function ($event) {
                            _vm.drawer = !_vm.drawer
                          },
                        },
                      },
                      [
                        _vm.drawer
                          ? _c(
                              "base-icon",
                              { attrs: { classes: "xl" } },
                              [_c("BaseSvg", { attrs: { name: "close" } })],
                              1
                            )
                          : _c(
                              "base-icon",
                              { attrs: { classes: "xl" } },
                              [_c("BaseSvg", { attrs: { name: "filters" } })],
                              1
                            ),
                      ],
                      1
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "navbar-toggler",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.back()
                          },
                        },
                      },
                      [
                        _c("base-icon", {
                          staticClass: "icon-lg",
                          attrs: { name: "arrow-left" },
                        }),
                      ],
                      1
                    ),
                _vm._v(" "),
                _vm.$store.state.ui.switch.web.header.formSearch
                  ? _c("the-header-search")
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "portal",
              {
                attrs: {
                  to: "sticky-footer-nav",
                  disabled: !["xs", "sm", "md"].includes(
                    _vm.$store.state.ui.client.breakpoint
                  ),
                },
              },
              [
                _c("the-header-nav-quick", {
                  on: {
                    "toggle-via": function ($event) {
                      return _vm.$emit("toggle-via")
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "the-navigation-menu" },
        [_c("the-navigation-menu", { attrs: { items: _vm.categories } })],
        1
      ),
      _vm._v(" "),
      _vm.drawer
        ? _c("the-navigation-menu-mobile", {
            attrs: { items: _vm.categories },
            model: {
              value: _vm.drawer,
              callback: function ($$v) {
                _vm.drawer = $$v
              },
              expression: "drawer",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }