<template>
    <header>
        <nav class="navbar">
            <div class="container-fluid navbar-container">
                <div class="navbar-main">
                    <logo class="navbar-brand me-3" />

                    <button
                        v-if="!showAppBtnBack && showCategories()"
                        type="button"
                        class="navbar-toggler"
                        :aria-expanded="drawer ? 'true' : 'false'"
                        aria-label="Toggle navigation"
                        @click="drawer = !drawer"
                    >
                        <base-icon
                            v-if="drawer"
                            classes="xl"
                        >
                            <BaseSvg name="close" />
                        </base-icon>
                        <base-icon
                            v-else
                            classes="xl"
                        >
                            <BaseSvg name="filters" />
                        </base-icon>
                    </button>
                    <button
                        v-else
                        type="button"
                        class="navbar-toggler"
                        @click="$router.back()"
                    >
                        <base-icon
                            name="arrow-left"
                            class="icon-lg"
                        />
                    </button>

                    <the-header-search v-if="$store.state.ui.switch.web.header.formSearch" />
                </div>

                <portal
                    to="sticky-footer-nav"
                    :disabled="!['xs', 'sm', 'md'].includes($store.state.ui.client.breakpoint)"
                >
                    <the-header-nav-quick @toggle-via="$emit('toggle-via')" />
                </portal>
            </div>
        </nav>

        <div class="the-navigation-menu">
            <the-navigation-menu
                :items="categories"
            />
        </div>

        <the-navigation-menu-mobile
            v-if="drawer"
            v-model="drawer"
            :items="categories"
        />
    </header>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { Portal } from 'portal-vue';
import TheHeaderNavQuick from './TheHeaderNavQuick.vue';
import Logo from './Logo.vue';
import TheNavigationMenu from './TheNavigationMenu.vue';
import TheNavigationMenuMobile from './TheNavigationMenuMobile.vue';
import { SOURCE } from '../utils/constants';

const TheHeaderSearch = defineAsyncComponent(() => import('./TheHeaderSearch.vue'));

export default {
    name: 'TheHeader',

    components: {
        Logo,
        TheHeaderSearch,
        TheHeaderNavQuick,
        TheNavigationMenu,
        TheNavigationMenuMobile,
        Portal,
    },

    data: () => ({
        drawer: false,
        showAppBtnBack: false,
    }),

    computed: {
        categories() {
            return this.$store.state.header.categories;
        },

        links() {
            return this.linksSource
                .filter(({ country }) => !country
                    || country === this.$store.state.params.country.id)
                .map((link) => {
                    if (link.children === 'PRODUCTS') {
                        Object.assign(link, {
                            children: this.$store.state.header.categories,
                        });
                    }

                    Object.assign(link, {
                        active: link.activeRoutes?.includes(this.$route.name),
                    });

                    return link;
                });
        },
    },

    watch: {
        $route() {
            this.updateShowingAppBtnBack();
        },
    },

    created() {
        this.linksSource = [
            {
                text: 'view_site_menu_products',
                icon: 'shopping-cart',
                to: '/products',
                activeRoutes: ['Listing', 'ListingVendor', 'ListingProducts', 'Search', 'Product'],
                accordion: true,
                children: 'PRODUCTS',
            },
        ];
    },

    mounted() {
        this.updateShowingAppBtnBack();
    },

    methods: {
        updateShowingAppBtnBack() {
            this.showAppBtnBack = this.isMobileApp() && this.$router.currentRoute.name !== 'Homepage'
                && typeof window !== 'undefined' && window.history.length;
        },
        isMobileApp() {
            return this.$store.state.params.source === SOURCE.APP;
        },
        showCategories() {
            let output = true;
            const isNotPermittedParentRoute = this.$route.matched.find((route) => ['Cart'].includes(route.name));
            if (
                isNotPermittedParentRoute
                || !this.$store.state.ui.switch.web.header.navCategories
            ) {
                output = false;
            }

            return output;
        },
        onClickTheHeaderMenu(event) {
            const classListOutside = ['the-header-menu', 'container-fluid'];
            const classListClicked = [...event.target.classList];
            const hasClickedOutside = classListClicked
                .some((className) => classListOutside.indexOf(className) !== -1);

            if (hasClickedOutside) {
                this.drawer = false;
            }
        },
    },
};
</script>

<style lang="scss">
@import "../scss/05-components/the-header";
</style>
