<template>
    <div class="container-fluid">
        <nav class="navigation-menu">
            <ul
                ref="menu"
                class="navigation-menu-items"
            >
                <li
                    v-for="(menuItem, index) in menuItems"
                    :key="index"
                    @mouseenter="onHover(index)"
                    @mouseleave="showDropdown = null"
                >
                    <component
                        :is="menuItem.domElementTag"
                        v-bind="menuItem.domElementTag === 'router-link'
                            ? { to: menuItem.href }
                            : { href: menuItem.href, target: '_blank' }"
                        class="nav-item"
                        :class="{ 'is-active': showDropdown === index }"
                    >
                        {{ menuItem.title }}
                    </component>

                    <div
                        v-if="menuItem?.children && menuItem.isLoaded"
                        :class="['nav-item-content', { 'is-visible': showDropdown === index }]"
                    >
                        <div class="container-fluid">
                            <div
                                v-if="menuItem.type === 'slider'"
                                class="nav-slider scroll scroll-x scroll-hide-bar"
                            >
                                <router-link
                                    v-for="(category, catIndex) in menuItem.children"
                                    :key="catIndex"
                                    :to="category.href"
                                    class="item"
                                >
                                    <base-img
                                        :src="category.imgUrl"
                                        :alt="category.title"
                                        class="image"
                                    />
                                    <span>{{ category.title }}</span>
                                </router-link>
                            </div>

                            <div
                                v-if="menuItem.type === 'tree'"
                                class="nav-tree"
                            >
                                <ul class="nav-tree-sidebar">
                                    <li
                                        v-for="(category, categoryIndex) in menuItem.children"
                                        :key="categoryIndex"
                                        @mouseenter="hoveredCategory = category"
                                    >
                                        <component
                                            :is="category.domElementTag"
                                            class="sidebar-item"
                                            :to="category.href"
                                            :class="{
                                                active: hoveredCategory?.title === category.title,
                                            }"
                                        >
                                            <base-img
                                                :src="getSrcCF(category.imgUrl, '50x50')"
                                                :alt="category.title"
                                                class="image"
                                            />
                                            <span>{{ category.title }}</span>
                                        </component>
                                    </li>
                                </ul>

                                <div class="nav-tree-items-wrapper">
                                    <template
                                        v-for="(category, categoryIndex) in menuItem.children"
                                    >
                                        <component
                                            :is="child.domElementTag"
                                            v-for="(child, childIndex) in category.children"
                                            :key="`${categoryIndex}_${childIndex}`"
                                            :to="child.href"
                                            class="menu-item"
                                            :class="{ 'is-visible': hoveredCategory === category }"
                                        >
                                            <base-img
                                                v-if="child.imgUrl"
                                                :src="getSrcCF(child.imgUrl, '50x50')"
                                                :alt="child.title"
                                                class="image"
                                            />
                                            <span class="title">{{ child.title }}</span>
                                        </component>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup>
import {
    computed, defineProps, onMounted, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router/composables';
import { getSrcCF } from '../utils';

const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
});

const showDropdown = ref(null);
const hoveredCategory = ref(null);

const menuItems = computed(() => (props.items || []).map((item) => ({
    ...item,
    isLoaded: false,
})));

const onHover = (index) => {
    const menuItem = menuItems.value[index];

    if (!menuItem.isLoaded) {
        menuItem.isLoaded = true;
    }

    showDropdown.value = index;
};

const route = useRoute();
watch(route, () => {
    showDropdown.value = null;
});

onMounted(() => {
    const [firstMenuItem] = menuItems.value;

    if (firstMenuItem?.children?.length) {
        [hoveredCategory.value] = firstMenuItem.children;
    }
});
</script>

<style lang="scss">
@import "../scss/05-components/the-navigation-menu";
</style>
