var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "nav" },
    [
      _vm.showVia
        ? _c(
            "base-btn",
            {
              staticClass: "nav-link nav-item",
              attrs: {
                variant: "link",
                title: _vm.$t("views_myaccount_menu_0012"),
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("toggle-via")
                },
              },
            },
            [_c("base-icon", [_c("BaseSvg", { attrs: { name: "via" } })], 1)],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.ui.switch.web.header.btnFavorites
        ? _c(
            "base-btn",
            {
              staticClass: "nav-link",
              attrs: {
                variant: "link",
                to: (_vm.user && "/account/favorites") || "",
                title: _vm.$t("views_myaccount_menu_0010"),
              },
              nativeOn: {
                click: function ($event) {
                  !_vm.user &&
                    _vm.$emitter.emit("modal", {
                      modal: "user",
                      type: "login",
                    })
                },
              },
            },
            [
              _c(
                "base-icon",
                {
                  attrs: {
                    classes: "lg",
                    "data-notification": _vm.user ? _vm.user.wishlistNumber : 1,
                  },
                },
                [
                  _c("BaseSvg", {
                    staticClass: "icon-favorites",
                    attrs: { path: "/icons/sprite", name: "favorites" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "base-btn",
            {
              staticClass: "nav-link nav-item",
              attrs: {
                variant: "link",
                href: "https://partners.vivre.eu/register",
                title: _vm.$t("view_site_menu_sell"),
                target: "_blank",
                rel: "nofollow noopener",
              },
            },
            [_c("base-icon", { attrs: { name: "add", classes: "lg" } })],
            1
          ),
      _vm._v(" "),
      _c("the-header-nav-quick-btn-cart", { staticClass: "nav-item" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nav-item" },
        [
          !_vm.user
            ? _c(
                "base-btn",
                {
                  staticClass: "nav-link",
                  attrs: {
                    variant: "link",
                    title: _vm.$t("layouts_main_header_0001"),
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$emitter.emit("modal", {
                        modal: "user",
                        type: "login",
                      })
                    },
                  },
                },
                [
                  _c(
                    "base-icon",
                    { attrs: { classes: "lg" } },
                    [_c("BaseSvg", { attrs: { name: "profile" } })],
                    1
                  ),
                ],
                1
              )
            : _c(
                "base-btn",
                {
                  staticClass: "nav-link",
                  attrs: {
                    variant: "link",
                    title: _vm.$t("views_myaccount_menu_0006"),
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.openModal("userMenu")
                    },
                  },
                },
                [
                  _c(
                    "base-icon",
                    { attrs: { classes: "lg" } },
                    [_c("BaseSvg", { attrs: { name: "profile" } })],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.modals.userMenu
        ? _c("modal-user-menu", {
            attrs: { title: _vm.user.name },
            on: {
              clicked: function ($event) {
                return _vm.closeModal("userMenu")
              },
            },
            model: {
              value: _vm.modals.userMenu,
              callback: function ($$v) {
                _vm.$set(_vm.modals, "userMenu", $$v)
              },
              expression: "modals.userMenu",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }