<template>
    <nav class="nav">
        <base-btn
            v-if="showVia"
            variant="link"
            class="nav-link nav-item"
            :title="$t('views_myaccount_menu_0012')"
            @click.native="$emit('toggle-via')"
        >
            <base-icon>
                <BaseSvg name="via" />
            </base-icon>
        </base-btn>

        <base-btn
            v-if="$store.state.ui.switch.web.header.btnFavorites"
            variant="link"
            :to="user && '/account/favorites' || ''"
            class="nav-link"
            :title="$t('views_myaccount_menu_0010')"
            @click.native="!user && $emitter.emit('modal', {
                modal: 'user',
                type: 'login',
            })"
        >
            <base-icon
                classes="lg"
                :data-notification="user ? user.wishlistNumber : 1"
            >
                <BaseSvg
                    path="/icons/sprite"
                    name="favorites"
                    class="icon-favorites"
                />
            </base-icon>
        </base-btn>

        <base-btn
            v-else
            variant="link"
            class="nav-link nav-item"
            href="https://partners.vivre.eu/register"
            :title="$t('view_site_menu_sell')"
            target="_blank"
            rel="nofollow noopener"
        >
            <base-icon
                name="add"
                classes="lg"
            />
        </base-btn>

        <the-header-nav-quick-btn-cart class="nav-item" />

        <div class="nav-item">
            <base-btn
                v-if="!user"
                variant="link"
                class="nav-link"
                :title="$t('layouts_main_header_0001')"
                @click.native="$emitter.emit('modal', {
                    modal: 'user',
                    type: 'login',
                })"
            >
                <base-icon classes="lg">
                    <BaseSvg name="profile" />
                </base-icon>
            </base-btn>

            <base-btn
                v-else
                variant="link"
                class="nav-link"
                :title="$t('views_myaccount_menu_0006')"
                @click.native="openModal('userMenu')"
            >
                <base-icon classes="lg">
                    <BaseSvg name="profile" />
                </base-icon>
            </base-btn>
        </div>

        <modal-user-menu
            v-if="modals.userMenu"
            v-model="modals.userMenu"
            :title="user.name"
            @clicked="closeModal('userMenu')"
        />
    </nav>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { modals } from '../utils/mixins';
import TheHeaderNavQuickBtnCart from './TheHeaderNavQuickBtnCart.vue';
import Logo from './Logo.vue';

const ModalUserMenu = defineAsyncComponent(() => import('./ModalUserMenu.vue'));

export default {
    name: 'TheHeaderNavQuick',

    components: {
        Logo,
        TheHeaderNavQuickBtnCart,
        ModalUserMenu,
    },

    mixins: [
        modals,
    ],

    computed: {
        user() {
            const { user } = this.$store.state;
            const userFullName = Object.prototype.hasOwnProperty.call(user, 'name');

            return (userFullName && user) || false;
        },

        showVia() {
            return this.$store.state.params.country.id === this.$COUNTRY.RO.ID;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/05-components/the-header-nav-quick";
</style>
