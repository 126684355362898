var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "navigation-menu-mobile" },
    [
      _setup.categoryHistory.length === 0
        ? [
            _c(
              "ul",
              { staticClass: "main-navigation" },
              _vm._l(_vm.items, function (category, index) {
                return _c(
                  "li",
                  { key: index },
                  [
                    _c(
                      category.domElementTag,
                      _vm._b(
                        {
                          tag: "component",
                          staticClass: "main-menu-item",
                          on: {
                            click: function ($event) {
                              return _setup.handleCategoryClick(category)
                            },
                          },
                        },
                        "component",
                        category.domElementTag === "router-link"
                          ? { to: category.href }
                          : { href: category.href, target: "_blank" },
                        false
                      ),
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(category.title) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        : [
            _setup.categoryHistory.length
              ? _c(
                  "button",
                  { staticClass: "back-button", on: { click: _setup.goBack } },
                  [
                    _c("base-icon", { attrs: { name: "arrow-left" } }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("site_ui_menu_back")) +
                        "\n        "
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "categories" },
              _vm._l(_setup.currentCategories, function (category, index) {
                return _c(
                  _setup.hasChildren(category) ? "div" : category.domElementTag,
                  {
                    key: index,
                    tag: "component",
                    staticClass: "menu-item",
                    attrs: {
                      to: !_setup.hasChildren(category) && category.href,
                    },
                    on: {
                      click: function ($event) {
                        return _setup.handleCategoryClick(category)
                      },
                    },
                  },
                  [
                    category.imgUrl
                      ? _c("base-img", {
                          staticClass: "image",
                          attrs: { src: category.imgUrl, alt: category.title },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(category.title)),
                    ]),
                  ],
                  1
                )
              }),
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }